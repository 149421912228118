function mascara($alvo, regra, alinharTexto) {
  var $campo = $alvo

  if (typeof $alvo == 'string') {
    $campo = document.querySelector($alvo)
  }

  if (! $campo) {
    return
  }

  mascaraDefinir($campo, regra)
  mascaraAlinhar($campo, alinharTexto)
  mascaraAplicar($campo)


  // aplica evento de mascara somente 1x
  if (! $campo.dataset.mascaraValidar) {
    $campo.dataset.mascaraValidar = '1'

    $campo.addEventListener('input', mascaraValidar)
  }


  // funcoes
  function mascaraDefinir($campo, regra) {
    $campo.dataset.mascaraRegra = regra

    // declara atributos somente se nao existirem santes
    if (! $campo.getAttribute('autocomplete')) {
      $campo.setAttribute('autocomplete', 'off')
    }

    if (! $campo.getAttribute('inputmode')) {
      $campo.setAttribute('inputmode', 'decimal')
    }

    if (! $campo.getAttribute('maxlength')) {
      $campo.setAttribute('maxlength', regra.length)
    }
  }

  function mascaraAlinhar($campo, alinharTexto) {

    if (alinharTexto && alinharTexto == 'direita') {
      $campo.style.textAlign = 'right'
    }
  }

  function mascaraValidar() {
    // validacao do campo e regra
    var $campo = event.currentTarget
    var checarCampo = $campo instanceof HTMLInputElement

    if (! checarCampo) {
      return
    }

    // validacao do keyup das setas
    var setaEsquerda = 37
    var setaCima = 38
    var setaDireita = 39
    var setaBaixo = 40
    var setaPrecionada = event.which == setaEsquerda || event.which == setaCima || event.which == setaDireita || event.which == setaBaixo

    if (! setaPrecionada) {
      mascaraAplicar($campo)
    }
  }

  function mascaraAplicar($campo) {
    var texto = $campo.value

    // recupera regra aplicada recente
    var regra = $campo.dataset.mascaraRegra

    if (! texto) {
      return
    }

    var caracteresNaoNumericos = /\D/g
    texto = texto.replace(caracteresNaoNumericos, '')

    var posicao = 0
    var retorno = ''

    for (var i = 0; i < regra.length; i++) {

      if (! texto[posicao]) {
        break
      }

      if (regra[i] == '#') {
        retorno += texto[posicao]
        posicao++
      }
      else if (regra[i] != texto[posicao]) {
        retorno += regra[i]
      }
    }

    $campo.value = retorno
  }
}